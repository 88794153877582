<!--
 * @Author: your name
 * @Date: 2020-10-26 15:19:02
 * @LastEditTime: 2021-03-05 15:18:08
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \trunk\src\pages\addType\seeTypeOne.vue
-->
<template>
  <div class="addType commonF padding20 bgFFF paddingLR20 paddingT20">
    <div
      class="title"
    >查看一级分类</div>
    <div class="content">
      <div class="commonF">
        <el-form ref="form" :model="form" label-width="100px">
          <el-form-item label="一级分类名称">
            <span>{{form.classifyOneName}}</span>
          </el-form-item>
          <el-form-item :label="$t('searchModule.remarks')">
            <el-input
              type="textarea"
              v-model="form.remarks"
              readonly
              style="width:230px"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <div style="text-align: center; margin-top: 20px;">
              <el-button @click="$router.go(-1)">返回</el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "seeType",
  data() {
    return {
      form: {
        classifyOneName: "",
        remarks: ""
      }
    };
  },
  mounted() {
    this.form = this.$route.query;
  }
};
</script>

<style lang="stylus" scoped>
.addType {
  .title {
    font-size: 24px;
    margin-bottom: 30px;
  }
}
</style>
