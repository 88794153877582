var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "addType commonF padding20 bgFFF paddingLR20 paddingT20" },
    [
      _c("div", { staticClass: "title" }, [_vm._v("查看一级分类")]),
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          { staticClass: "commonF" },
          [
            _c(
              "el-form",
              {
                ref: "form",
                attrs: { model: _vm.form, "label-width": "100px" },
              },
              [
                _c("el-form-item", { attrs: { label: "一级分类名称" } }, [
                  _c("span", [_vm._v(_vm._s(_vm.form.classifyOneName))]),
                ]),
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.$t("searchModule.remarks") } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "230px" },
                      attrs: { type: "textarea", readonly: "" },
                      model: {
                        value: _vm.form.remarks,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "remarks", $$v)
                        },
                        expression: "form.remarks",
                      },
                    }),
                  ],
                  1
                ),
                _c("el-form-item", [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "text-align": "center",
                        "margin-top": "20px",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.$router.go(-1)
                            },
                          },
                        },
                        [_vm._v("返回")]
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }